import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstance} from 'utils/http';
import {grade} from 'utils/urlConstant';

const initialState = {
  status: 'idle',
  data: null,
  error: null
};

export const fetchGrade = createAsyncThunk(
  'grade/fetchGrade',
  async (payload) => {
    try {
      const response = await axiosInstance.post(
        grade,
        payload,
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const gradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {
    // reducers
    resetGrade: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGrade.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGrade.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.data = action.payload?.result;
      })
      .addCase(fetchGrade.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? 'error';
      });
  }
});


export const {resetGrade} = gradeSlice.actions;

// selector
export const selectGrade = (state) => state.grade;

// reducer
export default gradeSlice.reducer;



