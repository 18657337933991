import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstance} from 'utils/http';
import {avatarAPI} from 'utils/urlConstant';

const initialState = {
  status: 'idle',
  videoURL: '',
  error: null
};

export const fetchAvatar = createAsyncThunk(
  'avatar/fetchAvatar',
  async (payload) => {
    try {
      const response = await axiosInstance.post(
        avatarAPI,
        payload,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const avatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    // reducers
    resetAvatar: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvatar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvatar.fulfilled, (state, action) => {
        state.status = 'success';
        state.videoURL = action.payload?.video_url || '';
      })
      .addCase(fetchAvatar.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? 'error';
      });
  }
});

export const {resetAvatar} = avatarSlice.actions;

// selector
export const selectAvtarData = (state) => state.avatar;

// reducer
export default avatarSlice.reducer;



