import {Box, Skeleton, Typography} from '@mui/material';
import ReadOnlyForm from './ReadOnlyForm';
import {useSelector} from 'react-redux';
import {selectGrade} from 'store/reducers/grade';


// sample results
// const result = [
//   {
//     'no': '1',
//     'type': 'objective',
//     'question': 'According to the text, what is a fundamental observation about everything in human experience and history?',
//     'options': {
//       'a': 'Everything falls to the earth',
//       'b': 'Everything floats in the air',
//       'c': 'Everything disappears into space',
//       'd': 'Everything moves randomly'
//     },
//     'answer': 'd',
//     'grading': {'score': '0'}
//   },
//   {
//     'no': '2',
//     'type': 'subjective',
//     'question': "Explain why Isaac Newton's observation of the falling apple was significant in the development of classical mechanics.",
//     'answer': "Isaac Newton's observation of the falling apple was significant in the development of classical mechanics because it led to the formulation of the law of universal gravitation, which describes the force of gravity between objects and is a fundamental principle in understanding the motion of celestial bodies and objects on Earth.",
//     grading:
//       {
//         'individual grade': {
//           'Clarity of Explanation': 4.5,
//           'Depth of Content': 4.5,
//           'Engagement': 3.5,
//           'Accuracy of Information': 4.5,
//           'Application of Knowledge': 4.0,
//           'Comprehension': 4.0
//         }, 'overall grad': 4.25
//       }
//   },
//   {
//     'no': '3',
//     'type': 'subjective',
//     'question': "Describe Isaac Newton's theory of gravity and its importance in understanding the force between two objects.",
//     'answer': "Isaac Newton's theory of gravity states that every object in the universe attracts every other object with a force that is directly proportional to the product of their masses and inversely proportional to the square of the distance between them. This theory is important in understanding the force between two objects because it provides a mathematical framework to calculate the gravitational force and predict the motion of objects under the influence of gravity.",
//     grading:
//       {
//         'individual grade': {
//           'Clarity of Explanation': 4.5,
//           'Depth of Content': 4.5,
//           'Engagement': 3.5,
//           'Accuracy of Information': 4.5,
//           'Application of Knowledge': 4.0,
//           'Comprehension': 4.0
//         }, 'overall grad': 4.25
//       }
//   },
//   {
//     'no': '4',
//     'type': 'objective',
//     'question': "According to Isaac Newton's universal law of gravitation, the force of gravity between two objects is determined by which of the following factors?",
//     'options': {
//       'a': 'The speed of light',
//       'b': 'The distance between the objects',
//       'c': 'The mass of the first object',
//       'd': 'The mass of the second object'
//     },
//     'answer': 'b',
//     'grading': {'score': '3'}
//   },
//   {
//     'no': '5',
//     'type': 'subjective',
//     'question': 'Explain why the force of gravity between two objects is negligible when compared to other forces in everyday situations.',
//     'answer': 'The force of gravity between two objects is negligible when compared to other forces in everyday situations because the gravitational force is relatively weak compared to electromagnetic forces and other forces at small distances. Additionally, the mass of everyday objects is much smaller compared to astronomical objects, further reducing the gravitational force between them.',
//     grading:
//       {
//         'individual grade': {
//           'Clarity of Explanation': 4.5,
//           'Depth of Content': 4.5,
//           'Engagement': 3.5,
//           'Accuracy of Information': 4.5,
//           'Application of Knowledge': 4.0,
//           'Comprehension': 4.0
//         }, 'overall grad': 4.25
//       }
//   }
//   // Add more result objects if needed
// ];


const Grade = () => {
  const {status, error, data} = useSelector(selectGrade);

  let content;

  if (status === 'loading') {
    content = (<>
      <Skeleton/>
      <Skeleton width={'60%'}/>
      <Skeleton width={'80%'}/>
    </>);
  } else if (status === 'succeed' && Array.isArray(data)) {
    content = (<ReadOnlyForm
      questions={data}
    />);
  } else if (status === 'failed' || (status === 'succeed' && typeof data === 'string')) {
    content = (<Typography variant='h5' color='error'>
      Grade fetching failed {error}
    </Typography>);
  } else {
    content = <Typography variant='body1'>No video uploaded</Typography>;
  }


  return (<Box width={'100%'} pb={3}>
    <Box display='inline-flex' gap={2}>
      <Typography variant='h5' fontWeight='bold' mb={3}>
        Grade
      </Typography>
    </Box>
    <Box>
      {content}
    </Box>
  </Box>);
};
export default Grade;
