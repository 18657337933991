import {useSelector} from 'react-redux';
import {selectAnalyticsData} from 'store/reducers/analyticsData';
import {Box, Chip, Typography} from '@mui/material';

const Summarization = () => {
  const {data, status} = useSelector(selectAnalyticsData);

  return (
    <Box>
      <Box display='inline-flex' gap={2}>
        <Typography variant='h5' fontWeight='bold' mb={3}>
          Lesson summary
        </Typography>
        <Chip
          label={status === 'succeed' ? data['Detected Language'] : 'Please Upload Video to detect language'}
          color={status === 'succeed' ? 'primary' : 'default'}
          sx={{mb: 3}}
        />
      </Box>
      <Typography variant='body1' textAlign='justify' whiteSpace='pre-line'>
        {data ? data['Video summary:'] : 'No video uploaded'}
      </Typography>
    </Box>
  );
};

export default Summarization;
