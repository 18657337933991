import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {Box, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import AppBarMain from 'layout/MainLayout/AppBarMain';
import Sidebar from 'layout/MainLayout/Sidebar';
import {useDispatch, useSelector} from 'react-redux';

import {selectLayout, setDrawer} from 'store/reducers/layout';
import Container from '@mui/material/Container';

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  // redux
  const {drawerOpen} = useSelector(selectLayout);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    dispatch(setDrawer(!drawerOpen));
  };

  useEffect(() => {
    dispatch(setDrawer(!matchDownMd));
  }, [dispatch, matchDownMd]);

  return (
    <Box sx={{display: 'flex'}}>
      <AppBarMain handleDrawerToggle={handleDrawerToggle}/>
      <Container sx={{
        display: 'flex',
        marginTop: '65px',
        paddingTop: 4,
        minHeight: 'calc(100vh - 65px)'
      }}>
        <Sidebar
          drawerOpen={drawerOpen}
          drawerToggle={handleDrawerToggle}
        />
        <Box display='flex'
          flexGrow={1}
          paddingY={1}
          width={'100%'}
          {...(!matchDownMd && {marginLeft: 6})}
        >
          <Outlet/>
        </Box>
      </Container>
    </Box>
  );
};

export default MainLayout;
