import LanguageIcon from '@mui/icons-material/Language';
import TranscribeIcon from '@mui/icons-material/Transcribe';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TranslateIcon from '@mui/icons-material/Translate';
import TaskIcon from '@mui/icons-material/Task';
import QuizIcon from '@mui/icons-material/Quiz';
import GradingIcon from '@mui/icons-material/Grading';
import HelpIcon from '@mui/icons-material/Help';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export const menuItems = [
  {
    id: 'languageDetection',
    pathName: 'language-detection',
    label: 'Language Detection',
    icon: <LanguageIcon/>
  },
  {
    id: 'lessonTranscription',
    pathName: 'lesson-transcription',
    label: 'Lesson Transcription',
    icon: <TranscribeIcon/>
  },
  {
    id: 'lessonSummary',
    pathName: 'lesson-summary',
    label: 'Lesson Summary',
    icon: <SummarizeIcon/>
  },
  {
    id: 'languageTranslation',
    pathName: 'language-translation',
    label: 'Language Translation',
    icon: <TranslateIcon/>
  },
  {
    id: 'things-to-remember',
    pathName: 'things-to-remember',
    label: 'Things To Remember',
    icon: <TaskIcon/>
  },
  {
    id: 'quiz',
    pathName: 'quiz',
    label: 'Quiz',
    icon: <QuizIcon/>
  },
  {
    id: 'grade',
    pathName: 'grade',
    label: 'Grade',
    icon: <GradingIcon/>
  },
  {
    id: 'avatar',
    pathName: 'avatar',
    label: 'Avatar',
    icon: <AccountBoxIcon/>
  },
  {
    id: 'qnaDoc',
    pathName: 'qna-doc',
    label: 'QnA Doc',
    icon: <HelpIcon/>
  }
];
