import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectAnalyticsData} from 'store/reducers/analyticsData';

const NoVideoRedirect = ({children}) => {
  const {data, status} = useSelector(selectAnalyticsData);

  if (status === 'idle' && !data) {
    return <Navigate to='/' replace/>;
  }

  return children;
};

export default NoVideoRedirect;
