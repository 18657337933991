import MainLayout from 'layout/MainLayout';
import NoMatch from 'pages/NoMatch';
import LanguageDetection from 'pages/LanguageDetection';
import Summarization from 'pages/Summarization';
import Transcription from 'pages/Transcription';
import LanguageTranslation from 'pages/LanguageTranslation';
import QnaDoc from 'pages/QnaDoc';
import Avatar from 'pages/Avatar';
import ActionItems from 'pages/ActionItems';
import Quiz from 'pages/Quiz';
import Grade from 'pages/Grade';
import NoVideoRedirect from './NoVideoRedirect';

const MainRoutes = {
  path: '/*',
  element: <MainLayout/>,
  children: [
    {
      path: 'language-detection',
      element: (
        <NoVideoRedirect>
          <LanguageDetection/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'lesson-transcription',
      element: (
        <NoVideoRedirect>
          <Transcription/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'lesson-summary',
      element: (
        <NoVideoRedirect>
          <Summarization/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'language-translation',
      element: (
        <NoVideoRedirect>
          <LanguageTranslation/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'qna-doc',
      element: (
        <NoVideoRedirect>
          <QnaDoc/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'avatar',
      element: (
        <NoVideoRedirect>
          <Avatar/>
        </NoVideoRedirect>
      )
    },
    {
      path: 'things-to-remember',
      element: (
        <NoVideoRedirect>
          <ActionItems/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'quiz',
      element: (
        <NoVideoRedirect>
          <Quiz/>
        </NoVideoRedirect>
      ),
    },
    {
      path: 'grade',
      element: (
        <NoVideoRedirect>
          <Grade/>
        </NoVideoRedirect>
      ),
    },
    {
      path: '*',
      element: <NoMatch/>,
    },
  ]
};

export default MainRoutes;
