import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {activeItem, selectLayout} from 'store/reducers/layout';
import CustomLink from 'component/CustomLink';

const NavItem = ({item}) => {
  const {id, label, pathName, icon} = item;
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const {openItem} = useSelector(selectLayout);

  const isSelected = openItem === id;

  const itemHandler = (id) => {
    dispatch(activeItem({openItem: id}));
  };

  useEffect(() => {
    if (pathname.includes(pathName)) {
      dispatch(activeItem({openItem: id}));
    }
    // eslint-disable-next-line
  }, [pathname]);


  return (
    <ListItem disablePadding sx={{paddingX: 1}}>
      <ListItemButton
        component={CustomLink}
        to={pathName}
        onClick={() => itemHandler(id)}
        selected={isSelected}
        sx={{
          mb: 1.5,
          borderRadius: 6,
          '&:hover': {
            bgcolor: 'primary.main',
            color: 'common.white',
            '.MuiListItemIcon-root': {
              color: 'common.white'
            }
          },
          '&.Mui-selected': {
            bgcolor: 'primary.main',
            color: 'common.white',
            '&:hover': {
              bgcolor: 'primary.main',
              color: 'common.white'
            },
            '.MuiListItemIcon-root': {
              color: 'common.white'
            }
          }
        }}
      >
        <ListItemIcon sx={{
          // ...(isSelected && {color: 'common.white'}),
        }}>{icon}</ListItemIcon>
        <ListItemText primary={label}/>
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
