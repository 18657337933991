import React from 'react';
import {Link} from 'react-router-dom';

const CustomLink = React.forwardRef(
  function _Link(linkProps, ref) {
    return <Link ref={ref} {...linkProps} />;
  }
);

export default CustomLink;
