import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstance} from 'utils/http';
import {quizGenerate} from 'utils/urlConstant';

const initialState = {
  status: 'idle',
  data: null,
  error: null
};

export const fetchQuiz = createAsyncThunk(
  'quiz/fetchQuiz',
  async (payload) => {
    try {
      const response = await axiosInstance.post(
        quizGenerate,
        payload,
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    // reducers
    resetQuiz: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuiz.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQuiz.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.data = action.payload?.result;
      })
      .addCase(fetchQuiz.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? 'error';
      });
  }
});


export const {resetQuiz} = quizSlice.actions;

// selector
export const selectQuiz = (state) => state.quiz;

// reducer
export default quizSlice.reducer;



