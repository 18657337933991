import {Input} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';

const FileInputButton = ({children, loading, onChange}) => {
  return (
    <label htmlFor='file-upload'>
      <Input
        type='file'
        id='file-upload'
        name='file-upload'
        sx={{display: 'none'}}
        inputProps={{
          accept: 'video/mp4,video/x-m4v,video/*',
        }}
        onChange={onChange}
      />
      <LoadingButton
        component='span'
        variant='contained'
        size='large'
        aria-label='upload-file'
        loading={loading}
        loadingPosition='start'
        startIcon={<CloudUploadIcon/>}
      >
        {children}
      </LoadingButton>
    </label>
  );
};

export default FileInputButton;
