import FileInputButton from 'component/FileInputButton';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectAnalyticsData,
  setVideoFile,
  uploadVideo
} from 'store/reducers/analyticsData';
import {useEffect} from 'react';
import {fetchActionItems} from 'store/reducers/actionItems';
import {fetchQuiz} from 'store/reducers/quiz';
import {useNavigate} from 'react-router-dom';


const FileUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {data, status} = useSelector(selectAnalyticsData);

  const handleFileUpload = (event) => {
    const uploadedVideo = event.target.files[0];
    const formData = new FormData();
    formData.append('video', uploadedVideo);

    dispatch(setVideoFile(uploadedVideo));
    dispatch(uploadVideo(formData));
  };

  useEffect(() => {
    // call api when data is available
    if (data) {
      // fetch avatar
      // const formData = new FormData();
      // formData.append('clips', 'jack');
      // formData.append('input_text', data['Video summary:'] || '');
      // dispatch(fetchAvatar(formData));

      // fetch action items
      dispatch(fetchActionItems({
        session_id: '8jesu126q00karonurlpawfltutwvtnh',
        currentUser: 'react-app',
        context: data['Captions:'] || '',
      }));

      // fetch quiz
      dispatch(fetchQuiz({
        session_id: '8jesu126q00karonurlpawfltutwvtnh',
        currentUser: 'react-app',
        context: data['Captions:'] || '',
      }));

      // navigate to language detection page
      navigate('language-detection');
    }
  }, [dispatch, navigate, data]);

  return (
    <FileInputButton loading={status === 'loading'} onChange={handleFileUpload}>
      Upload Video
    </FileInputButton>
  );
};

export default FileUpload;
