import {useSelector} from 'react-redux';
import {selectAnalyticsData} from '../../store/reducers/analyticsData';
import {Box, Chip, Typography} from '@mui/material';

const LanguageTranslation = () => {

  const {data, status} = useSelector(selectAnalyticsData);
  return (
    <Box>
      <Box display='inline-flex' gap={2}>
        <Typography variant='h5' fontWeight='bold' mb={3}>
          Language Translation
        </Typography>
        <Chip
          label={status === 'succeed' ? 'Spanish' : 'Please Upload Video to detect language'}
          color={status === 'succeed' ? 'primary' : 'default'}
          sx={{mb: 3}}
        />
      </Box>
      <Typography variant='body1' textAlign='justify' whiteSpace='pre-line'>
        {data ? data['German Translation of summary:'] : 'No video uploaded'}
      </Typography>
    </Box>
  );
};

export default LanguageTranslation;
