import {Box, Chip, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectAnalyticsData,} from 'store/reducers/analyticsData';

const LanguageDetection = () => {
  const {data, status, videoFile} = useSelector(selectAnalyticsData);

  // const onUploadProgress = useCallback((progressEvent) => {
  //   const {loaded, total} = progressEvent;
  //   const percent = Math.floor((loaded * 100) / total);
  //   // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
  //   setProgress(percent);
  // }, []);

  return (
    <Box width={'100%'}>
      <Typography variant='h5' fontWeight='bold' mb={3}>
        Language Detected
      </Typography>
      <Chip
        label={status === 'succeed' ? data['Detected Language'] : 'Please Upload Video to detect language'}
        color={status === 'succeed' ? 'primary' : 'default'}
        sx={{mb: 3}}
      />
      {
        videoFile && (
          <Box
            component='video'
            display='block'
            sx={{
              maxWidth: '100%',
              objectFit: 'cover',
              aspectRatio: '16 / 9',
              borderRadius: 3
            }}
            maxHeight={400}
            controls
          >
            <source src={URL.createObjectURL(videoFile)}
              type='video/mp4'/>
          </Box>
        )
      }
    </Box>
  );
};

export default LanguageDetection;
