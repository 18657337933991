import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  defaultId: 'languageDetection',
  openItem: 'languageDetection',
  drawerOpen: true,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },
    setDrawer: (state, action) => {
      state.drawerOpen = action.payload;
    },
  },
});

// action
export const { setDrawer, activeItem } = layoutSlice.actions;

// selector
export const selectLayout = (state) => state.layout;

// reducer
export default layoutSlice.reducer;
