import {useLocation, useNavigate} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import {AppBar, Box, IconButton, Toolbar, useMediaQuery,} from '@mui/material';

import Logo from 'assets/logo/panopto-logo.svg';
import {useDispatch} from 'react-redux';
import {resetAnalyticsData} from 'store/reducers/analyticsData';
import CustomLink from 'component/CustomLink';
import {useTheme} from '@mui/material/styles';
import {resetActionItems} from '../../../store/reducers/actionItems';
import {resetQuiz} from '../../../store/reducers/quiz';

const AppBarMain = ({handleDrawerToggle}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();


  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      dispatch(resetAnalyticsData());
      dispatch(resetActionItems());
      dispatch(resetQuiz());
      navigate('/', {replace: true});
    }
  };


  return (
    <AppBar
      enableColorOnDark
      position='fixed'
      elevation={1}
      sx={{backgroundColor: 'background.paper'}}>
      <Toolbar>
        {/* menu button */}
        {
          !matchUpMd && handleDrawerToggle && (
            <IconButton
              size='large'
              edge='start'
              aria-label='menu'
              sx={{mr: 2}}
              onClick={handleDrawerToggle}>
              <MenuIcon/>
            </IconButton>
          )
        }
        {/* logo section */}
        <Box width='100%' textAlign='center'
          component={CustomLink}
          to={'/'}
          onClick={handleLogoClick}
        >
          <Box component='img' src={Logo} alt={'logo'}/>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarMain;
