import {createTheme} from '@mui/material';

const colors = {
  primaryMain: '#30733F',
  primaryText: '#272727',
};


const theme = createTheme({
  palette: {
    primary: {
      main: colors.primaryMain,
    },
    text: {
      primary: colors.primaryText,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
        },
      },
    },
  },
});

export default theme;
