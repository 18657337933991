import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

const ReadOnlyForm = ({questions}) => {

  const getParsedValue = (s) => {
    try {
      return JSON.parse(s);
    } catch (e) {
      return {};
    }
  };

  return (<>
    {questions.map((item, index) => (<Box variant='outlined' key={item.no}
      sx={{
        p: 2,
        mb: 2,
        borderRadius: 2,
        background: '#30733F0D'
      }}
    >
      <Typography variant='body1' fontWeight='bold'>
        {`Question ${item.no}: ${item.question}`}
      </Typography>
      {item.type === 'objective' ? (<>
        <FormControl component='fieldset'>
          <RadioGroup
            defaultValue={item?.answer || ''}
          >
            {Object.entries(item.options).map(([key, value]) => (
              <FormControlLabel
                key={key}
                value={key}
                disabled
                control={<Radio
                />}
                label={`${key.toLocaleUpperCase()}: ${value}`}/>))}
          </RadioGroup>
        </FormControl>
        <Typography variant='h6' fontWeight='bold'>
          Grading: {' '}
          <Box component='span'
            color='primary.main'
          >{getParsedValue(item.grading)?.score || 'N/A'}</Box>
        </Typography>
      </>) : (<>
        <TextField
          fullWidth
          multiline
          margin='dense'
          minRows={3}
          disabled
          defaultValue={item?.answer || ''}
          sx={{background: '#fff'}}
        />
        <Typography variant='h6' fontWeight='bold'>
          Grading {''}
          <Box component='span'
            color='primary.main'>{!item.grading ? 'N/A' : ''}</Box>
        </Typography>
        {Object.entries(getParsedValue(item.grading)?.['individual grade'] || {}).map(([key, value]) => (
          <Typography variant='body1' fontWeight='bold'
            color='primary.main' key={key}>
            {`${key}: ${value}`}
          </Typography>))}
        <Typography variant='body1' fontWeight='bold'
          color='darkgreen'>
          Overall Grad {' '}
          {getParsedValue(item.grading)?.['overall grad']}
        </Typography>
      </>)}

    </Box>))}
  </>);
};

export default ReadOnlyForm;
