import {Box, Skeleton, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectActionItemsData} from 'store/reducers/actionItems';

const ActionItems = () => {
  const {data, error, status} = useSelector(selectActionItemsData);

  return (<Box>
    <Typography variant='h5' fontWeight='bold' mb={3}>
      Things To Remember
    </Typography>
    <Typography variant='body1' textAlign='justify' whiteSpace='pre-line'>
      {error ? error : (status === 'loading') ? (<>
        <Skeleton/>
        <Skeleton width={'60%'}/>
        <Skeleton width={'80%'}/>
      </>) : data ? data.result : 'No video uploaded'}
    </Typography>
  </Box>);
};

export default ActionItems;
