import {Box, Grid, Typography} from '@mui/material';
import Container from '@mui/material/Container';
import Landing from 'assets/landing.svg';
import FileUpload from './FileUpload';
import {useSelector} from 'react-redux';
import {selectAnalyticsData} from 'store/reducers/analyticsData';

const Homepage = () => {
  const {videoFile} = useSelector(selectAnalyticsData);

  return (
    <Container sx={{display: 'flex'}}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5} display='flex' flexDirection='column'
          justifyContent='center'>
          <Box variant='h2' mb={4}
            sx={{
              typography: {xs: 'h4', sm: 'h3'},
              fontWeight: '700 !important'
            }}>
            Learnify
          </Box>
          <Typography variant='body1' mb={4}>
            A platform infused with artificial intelligence for
            learning and assessment.
          </Typography>
          <Box mb={2}>
            <FileUpload/>
          </Box>
          {
            videoFile && (
              <Box
                component='video'
                display='block'
                sx={{
                  maxWidth: '100%',
                  objectFit: 'cover',
                  aspectRatio: '16 / 9',
                  borderRadius: 3
                }}
                maxHeight={400}
                controls
              >
                <source src={URL.createObjectURL(videoFile)}
                  type='video/mp4'/>
              </Box>
            )
          }
        </Grid>
        <Grid item xs={12} md={7} display='flex' flexDirection='column'
          justifyContent='center'>
          <Box
            component='img'
            src={Landing}
            alt='landing'
            height='60%'
            maxWidth='100%'/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Homepage;
