import {Alert, Box, Skeleton, Snackbar, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {selectQuiz} from 'store/reducers/quiz';
import Form from './Form';
import {fetchGrade} from 'store/reducers/grade';
import {useNavigate} from 'react-router-dom';

const Quiz = () => {
  const [userAnswers, setUserAnswers] = useState([]);
  const [open, setOpen] = useState(false);

  const {data, error, status} = useSelector(selectQuiz);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleInputChange = (index, answer) => {
    setUserAnswers((prevAnswers) => {
      const copyUserAnswers = [...prevAnswers];
      copyUserAnswers[index] = {...copyUserAnswers[index], answer};
      return copyUserAnswers;
    });
  };

  const handleSubmit = () => {
    // You can now access userAnswers state to do something with the user's responses
    dispatch(fetchGrade({
      session_id: '8jesu126q00karonurlpawfltutwvtnh',
      currentUser: 'react-app',
      context: userAnswers || []
    }));
    setOpen(true);
    setTimeout(() => {
      navigate('/grade');
    }, 2500);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (Array.isArray(data) && userAnswers.length === 0) {
      const answers = data.slice().sort((a, b) => (parseInt(a.no) - parseInt(b.no)))
        .map(result => ({...result, answer: ''}));
      setUserAnswers(answers);
    }
  }, [data, userAnswers]);


  let content;

  if (status === 'loading') {
    content = (<>
      <Skeleton/>
      <Skeleton width={'60%'}/>
      <Skeleton width={'80%'}/>
    </>);
  } else if (status === 'succeed') {
    content = (<Form
      userAnswers={userAnswers}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
    />);
  } else if (status === 'failed') {
    content = (<Typography variant='h5' color='error'>
      quiz fetching failed {error}
    </Typography>);
  } else {
    content = <Typography variant='body1'>No video uploaded</Typography>;
  }


  return (<Box width={'100%'} pb={3}>
    <Box display='inline-flex' gap={2}>
      <Typography variant='h5' fontWeight='bold' mb={3}>
        Quiz
      </Typography>
    </Box>
    <Box>
      {content}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity='success'
          variant='filled'
          sx={{width: '100%'}}
        >
          Answer submitted successfully.
        </Alert>
      </Snackbar>
    </Box>
  </Box>);
};

export default Quiz;
