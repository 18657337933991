import {
  Box,
  Button,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectAnalyticsData} from 'store/reducers/analyticsData';
import {useState} from 'react';
import {axiosInstance} from 'utils/http';
import {qnaDoc} from 'utils/urlConstant';

const QnaDoc = () => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);

  const {data, status} = useSelector(selectAnalyticsData);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const onSubmit = () => {
    const payload = {
      query,
      session_id: '8jesu126q00karonurlpawfltutwvtnh',
      currentUser: 'react-app',
      context: data['Captions:'] || '',
    };

    setLoading(true);

    axiosInstance.post(qnaDoc, payload)
      .then(response => {
        setLoading(false);
        setResponse(response.data);
      })
      .catch(error => {
        console.error('Error while fetching QnA', error);
        setLoading(false);
        setError(true);
      });
  };

  return (
    <Box width={'100%'}>
      <Box display='inline-flex' gap={2}>
        <Typography variant='h5' fontWeight='bold' mb={3}>
          QnA Doc
        </Typography>
      </Box>
      <Box variant='outlined' sx={{p: 3, w: '100%', bgcolor: '#30733F0D'}}>
        {data ?
          (
            <Stack spacing={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  fullWidth
                  size='small'
                  label='Query'
                  value={query}
                  onChange={handleQueryChange}
                  sx={{background: '#fff'}}
                />
                <Button
                  variant='contained'
                  onClick={onSubmit}
                  disabled={loading}>Ask</Button>
              </Stack>
              <Paper variant='outlined' sx={{p: 2}}>
                {
                  loading && (
                    <>
                      <Skeleton/>
                      <Skeleton width={'60%'}/>
                      <Skeleton width={'80%'}/>
                    </>
                  )
                }
                {
                  !loading && error && (
                    <Typography variant='body2' color='error'>
                      Something went wrong
                    </Typography>
                  )
                }
                {
                  !loading && !response && !error && (
                    <Typography variant='body2' color={'grey'}>
                      Search any query to get a response based on the
                      transcription
                    </Typography>
                  )
                }
                {
                  !loading && response ? (
                    <Typography variant='body2' color={'grey'}>
                      {response.result}
                    </Typography>
                  ) : null
                }
              </Paper>
            </Stack>
          )
          : (<Typography variant='h5'>
            No video uploaded
          </Typography>)
        }
      </Box>
    </Box>
  );
};

export default QnaDoc;
