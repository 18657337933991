import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstance} from 'utils/http';
import {actionItems} from 'utils/urlConstant';

const initialState = {
  status: 'idle',
  data: null,
  error: null
};

export const fetchActionItems = createAsyncThunk(
  'actionItems/fetchActionItems',
  async (payload) => {
    try {
      const response = await axiosInstance.post(
        actionItems,
        payload,
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const actionItemsSlice = createSlice({
  name: 'actionItems',
  initialState,
  reducers: {
    // reducers
    resetActionItems: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchActionItems.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.data = action.payload;
      })
      .addCase(fetchActionItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? 'error';
      });
  }
});


export const {resetActionItems} = actionItemsSlice.actions;

// selector
export const selectActionItemsData = (state) => state.actionItems;

// reducer
export default actionItemsSlice.reducer;



