import {combineReducers} from 'redux';

import layout from './layout';
import analyticsData from './analyticsData';
import avatar from './avatar';
import actionItems from './actionItems';
import quiz from './quiz';
import grade from './grade';

const reducers = combineReducers({
  layout,
  analyticsData,
  avatar,
  actionItems,
  quiz,
  grade
});

export default reducers;
