import {List} from '@mui/material';
import {menuItems} from 'layout/MenuItems';

import NavItem from './NavItem';

const Navigation = () => {
  return (
    <List color='inherit'>
      {menuItems.map(menuItem => (
        <NavItem key={menuItem.id} item={menuItem}/>
      ))}
    </List>
  );
};

export default Navigation;
