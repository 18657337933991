import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstance} from 'utils/http';
import {videoToCaptions} from 'utils/urlConstant';

const initialState = {
  status: 'idle',
  data: null,
  error: '',
  videoFile: null,
};

export const uploadVideo = createAsyncThunk(
  'analyticsData/uploadVideo',
  async (payload) => {
    try {
      const response = await axiosInstance
        .post(videoToCaptions, payload, {
          headers: {'Content-Type': 'multipart/form-data'},
          // onUploadProgress,
        });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const analyticsDataSlice = createSlice({
  name: 'analyticsData',
  initialState,
  reducers: {
    setVideoFile: (state, action) => {
      state.videoFile = action.payload;
    },
    setAnalyticsData: (state, action) => {
      state.data = action.payload;
    },
    resetAnalyticsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadVideo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.data = action.payload;
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? 'error';
      });
  }
});

export const {
  setAnalyticsData,
  setVideoFile,
  resetAnalyticsData
} = analyticsDataSlice.actions;

export const selectAnalyticsData = (state) => state.analyticsData;

export default analyticsDataSlice.reducer;
