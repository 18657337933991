import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import MaleAvatar from 'assets/avatar/jack.png';
import FemaleAvatar from 'assets/avatar/amy.png';
import {useSelector} from 'react-redux';
import {selectAnalyticsData} from 'store/reducers/analyticsData';
import {useState} from 'react';

const AvatarStatic = () => {
  const {data, status: analyticsDataStatus} = useSelector(selectAnalyticsData);
  const [value, setValue] = useState('jack');
  const videoURL = `${process.env.PUBLIC_URL}/avatar-video/${value === 'jack' ? 'jack' : 'amy'}.mp4`;

  console.log(videoURL);

  const handleChange = (event) => {
    const genderValue = event.target.value;
    setValue(genderValue);
  };

  let content = null;

  if (data) {
    content = (
      <Box
        component='video'
        sx={{
          maxWidth: '100%',
          objectFit: 'contain',
          aspectRatio: '1 / 1',
          borderRadius: 2
        }}
        height={440}
        controls
        controlsList='nodownload'
        src={videoURL} type='video/mp4'
      />
    );
  }


  return (
    <Box width={'100%'} pb={3}>
      <Box display='inline-flex' gap={2}>
        <Typography variant='h5' fontWeight='bold' mb={3}>
          Avatar
        </Typography>
      </Box>
      <Box mb={2}>
        <FormControl disabled={analyticsDataStatus !== 'succeed'}>
          <FormLabel id='demo-controlled-radio-buttons-group'>Gender</FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value='jack'
              label='Male'
              labelPlacement={'bottom'}
              control={
                <Radio
                  icon={
                    <Box component='img'
                      src={MaleAvatar}
                      width={80}
                      borderRadius={'50%'}
                      border={1}
                      padding={0.5}
                      alt={'male-avatar'}/>
                  }
                  checkedIcon={
                    <Box component='img'
                      src={MaleAvatar}
                      width={80}
                      borderRadius={'50%'}
                      border={2}
                      padding={0.5}
                      alt={'male-avatar'}/>
                  }
                />
              }

            />
            <FormControlLabel
              value='amy'
              label='Female'
              labelPlacement={'bottom'}
              control={
                <Radio
                  icon={
                    <Box
                      component='img'
                      src={FemaleAvatar}
                      width={80}
                      borderRadius={'50%'}
                      border={1}
                      padding={0.5}
                      alt={'female-avatar'}/>
                  }
                  checkedIcon={
                    <Box
                      component='img'
                      src={FemaleAvatar}
                      width={80}
                      borderRadius={'50%'}
                      border={2}
                      padding={0.5}
                      loading='lazy'
                      alt={'female-avatar'}/>
                  }
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        {content}
      </Box>
    </Box>
  );
};

export default AvatarStatic;
