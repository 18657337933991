import {Outlet} from 'react-router-dom';
import AppBarMain from 'layout/MainLayout/AppBarMain';
import {Box} from '@mui/material';

const MinimalLayout = () => (
  <Box sx={{display: 'flex'}}>
    <AppBarMain/>
    <Box
      display='flex'
      flexGrow={1}
      marginTop={'65px'}
      padding={3}
      width={'100%'}
      minHeight={'calc(100vh - 65px)'}>
      <Outlet/>
    </Box>
  </Box>
);

export default MinimalLayout;
