import MinimalLayout from 'layout/MinimalLayout';
import Homepage from '../pages/HomePage';

const MinimalRoutes = {
  path: '/',
  element: <MinimalLayout/>,
  children: [
    {
      path: '/',
      element: <Homepage/>
    }
  ]
};

export default MinimalRoutes;
