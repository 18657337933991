import CssBaseline from '@mui/material/CssBaseline';
import {Provider} from 'react-redux';
import {store} from './store';
import {BrowserRouter} from 'react-router-dom';
import Routes from 'routes';
import {ThemeProvider} from '@mui/material';
import theme from 'theme';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Routes/>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
