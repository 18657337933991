import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

const Form = ({userAnswers, handleInputChange, handleSubmit}) => {

  return (
    <>
      {userAnswers.map((item, index) => (
        <Box variant='outlined' key={item.no}
          sx={{
            p: 2,
            mb: 2,
            borderRadius: 2,
            background: '#30733F0D'
          }}
        >
          <Typography variant='body1' fontWeight='bold'>
            {`Question ${item.no}: ${item.question}`}
          </Typography>
          {item.type === 'objective' ? (
            <FormControl component='fieldset'>
              <RadioGroup
                value={item?.answer || ''}
                onChange={(e) => handleInputChange(index, e.target.value)}
              >
                {Object.entries(item.options).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={
                      <Radio
                      />
                    }
                    label={`${key.toLocaleUpperCase()}: ${value}`}/>
                ))}
              </RadioGroup>
            </FormControl>
          )
            :
            <TextField
              // defaultValue={item.correct}
              fullWidth
              multiline
              label='Answer'
              margin='dense'
              minRows={3}
              value={item?.answer || ''}
              sx={{background: '#fff'}}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          }
        </Box>
      ))}
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
        >
          Submit Answers
        </Button>
      </Box>
    </>
  );
};

export default Form;
