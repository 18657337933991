import {Box, Drawer, useMediaQuery} from '@mui/material';
import Navigation from './Navigation';
import {useTheme} from '@mui/material/styles';

const drawerWidth = 265;

const Sidebar = ({drawerOpen, drawerToggle, window}) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (<Box
    component='nav'
    aria-label='sidebar'
    sx={{
      flexShrink: {md: 0}, width: matchUpMd ? drawerWidth : 'auto',
    }}
  >
    {!matchUpMd ? (<Drawer
      open={drawerOpen}
      onClose={drawerToggle}
      container={container}
      variant={matchUpMd ? 'persistent' : 'temporary'}
      anchor='left'
      color='inherit'
      sx={{
        width: drawerWidth, ...(!matchUpMd && !drawerOpen ? {visibility: 'hidden'} : {}),
        '& .MuiDrawer-paper': {
          width: drawerWidth, boxSizing: 'border-box',
        },
      }}>
      <Navigation/>
    </Drawer>) : (<Navigation/>)}
  </Box>);
};

export default Sidebar;
