export const videoToCaptions = 'https://analyticsdemo.rsystems.com:8002/video-to-caption';

export const qnaDoc = 'https://aidemo.rsystems.com:8000/generalized_qna_app/generic_qna_openai/';

export const avatarAPI = 'https://analyticsdemo.rsystems.com:8003/text-to-avatar';

export const actionItems = 'https://aidemo.rsystems.com:8000/generalized_qna_app/generic_actionitems_openai/';

export const quizGenerate = 'https://aidemo.rsystems.com:8000/generalized_qna_app/quizgenerator_openai/';

export const grade = 'https://aidemo.rsystems.com:8000/generalized_qna_app/grading_openai/';
